<template>
    <div class="terminierung">
        <div class="highlight">Intelligente Terminverwaltung ergibt effiziente Ressourcen-Nutzung</div>
        <div class="box">
            <h1>Terminierung</h1>
            <p>
                <b>Eine intelligente Terminverwaltung,</b> die Übersicht schafft, muss nicht nur den Zeitpunkt bzw. Zeitraum verwalten sondern auch die genutzten Ressourcen.  
                Im Beispiel von AVALON Orga geht es um Mitarbeiter und Arbeitsplätze.<br><b> Grundsätzlich kann AVALON Orga alle möglichen Ressourcen verwalten.</b><br><br>
                Bereits bei der Terminbuchung wird eine kaufmännische Kategorisierung mit Artikeln bzw. Leistungen vorgenommen. 
                 
                Umfangreiche Automatismen erlauben dem Anwender die Leistungszuweisung bei Terminserien vorzunehmen.
            </p>
        </div>
       <div class="box transparent">
            <kleine-boxen class="text-box blend-in" v-on-scroll.repeat>
                <span>Gebuchte Termine werden automatisch per SMS oder E-Mail an den Kunden kommuniziert.*</span>
                <span>Das Managementsystem* verwaltet auch wiederkehrende Termine (z.B. Impftermine). </span>
                <span>Avalon Orga erinnert automatisch die Patienten am Vorabend per SMS (wenn gewünscht).</span>
            </kleine-boxen>
        </div>
        <div class="box">
            <h4>Im ersten Schritt geht die Initiative vom Patienten aus, der seinen Terminwunsch über zwei mögliche Wege eingibt:</h4>
            <div class="columns">
                <div>
                    <center><b>Online*</b></center><br>
                    <img src="../assets/online_buchen_300px.jpg" alt=""/>
                </div>
                <div>
                    <center><b>Klassisch am Telefon</b></center><br>
                    <img src="../assets/telefon_buchen_300px.jpg" alt=""/><br><br>
                </div>
            </div>
            <p>
                In beiden Fällen werden die Möglichkeiten, dem Terminwunsch zu entsprechen, geprüft und dem Patienten bestätigt oder Alternativen vorgeschlagen.<br><br>
                <b>Nach dem der Termin gebucht, einem Therapeut und einem Therapieraum zugeordnet wurde, ist das Zeitfenster vollständig.</b><br><br><small>*optional</small>
            </p>
        </div>
        <div class="box transparent">
            <kleine-boxen class="tabs" v-on-scroll.repeat>
                <button @click="tab = 'terminbuchung'" :class="{active: tab == 'terminbuchung'}">
                    Buchungssystem</button>
                <button @click="tab = 'terminplanung'" :class="{active: tab == 'terminplanung'}">
                    Terminplanung</button>
                <button @click="tab = 'terminbestätigung'" :class="{active: tab == 'terminbestätigung'}">
                    Terminbestätigung</button>
            </kleine-boxen>
        </div>
        <template v-if="tab == 'terminbuchung'">
            <div class="box">
                <h2> Terminbuchung / -Anfrage</h2>
                <div class="columns">
                    <div>
                        <p>
                            <b>In der Online-Ansicht für Patienten werden freie Termine auf ihrer Webseite angezeigt.</b>
                        <ul>
                            <li>Einbindung und Verlinkung auf ihrer Homepage. </li>
                            <li>Nur buchbare Termine sind sichtbar.</li>
                            <li>Der Patient füllt seine persönlichen Informationen vollständig aus.</li>
                            <li>Zusätzlich werden der Anwendungswunsch bzw. die Verordnung des Arztes eingegeben.</li>
                            <li>Durch Anklicken eines Feldes wird ein Terminwunsch ausgewählt.</li>
                            <li>Es können sowohl Einzeltermine als auch Terminserien gewählt werden. </li>
                            <li>Der Patient erhält eine Bestätigung seiner Buchungsanfrage, Information über den Status und den weiteren Verlauf.</li>
                        </ul>
                        </p>
                    </div>
                    <div>
                        <center><h3>Online-Buchung*</h3></center>
                        <img src="../assets/onlinebuchen_monitor.png" alt=""/><br><br>
                    </div>
                </div>
                <p>
                    <b>Bei Onlinebuchungen handelt es sich um unverbindliche Terminanfragen, daher ist es wichtig, dass Onlineanfragen zeitnah bearbeitet, 
                       gebucht und dem Kunden bestätigt werden!</b><br><br><small>*optional</small>
                </p>
            </div>
            <div style="height: 5em"></div>
            <div class="box">
                <h3>Telefonische oder persönliche Buchung</h3>
                <div class="columns">
                    <div>
                        In der <b>Tagesübersicht der Mitarbeiter</b>, sind die freien Zeitfenster (gelb) und die bereits belegten Termine (grün) direkt sichtbar.<br>
                        Ein freier Termin wird per Mausklick für den Patienten eingebucht.<br><br>
                        Ggf. wird mit dem Patienten ein Alternativtermin abgestimmt und direkt dem passenden Zeitfenster zugeordnet. 
                        Der Patient erhält parallel eine Buchungsbestätigung per E-Mail oder SMS.
                    </div> 
                    <div>
                        <img src="../assets/mitarbeiter_monitor.png" alt=""/>
                     </div>
                </div>
            </div>
        </template>
        <template v-else-if="tab == 'terminplanung'">
            <div class="box">
                <h2>Terminplanung</h2>
                <p>
                    Die über beide möglichen Kanäle eingegangenen Terminanfragen werden vom Backoffice-Team finalisiert.<br><br> 
                    Die Online-Terminanfragen werden (im besten Fall) vor der Praxisöffnung bearbeitet und nach Möglichkeit 
                    fix gebucht als auch dem Patienten bestätigt. Falls das Zeitfenster zwischenzeitlich vergeben ist stimmt das Backoffice-Team 
                    Alternativen ab.<br><br>
                    Eine Terminanfrage, egal ob online oder analog, wird vervollständigt indem folgende Punkte zugeordnet werden:<br>
                </p>
                <div class="columns" style="padding-top: 2em">
                    <div>
                        <ul>
                            <li>Behandlung mit qualifizierten Therapeut</li>
                            <li>passender Behandlungsraum </li>
                            <li>Patienten </li>
                            <li>vorgesehenen Zeitfenster</li>
                        </ul>
                    </div>
                    <div>
                        <img src="../assets/terminplanung_duo.png" alt=""/>
                    </div>
                </div>
            </div>
        </template>
        <template v-else-if="tab == 'terminbestätigung'">
            <div class="box">
                <h2>Terminbestätigung</h2>
                <p>                   
                    Das System benutzt sowohl online als auch für analoge Buchungen den selben Kalender. 
                    <b>Doppelbuchungen von Therapeuten und Behandlungsräumen sind dadurch ausgeschlossen.</b><br><br>
                </p>
                <p>
                    Nach <b>vervollständigen der Termine, "Zusammenführung" </b> von 
                </p><br>
                <div class="columns">
                        <p>
                            <ul>
                                <li>Raum</li>
                                <li>Patient</li>
                                <li>Therapeut</li>
                                <li>Datum / Zeit</li>
                            </ul><br>
                            durch das Backoffice-Team, erhält der Patient eine Terminbestätigung per E-Mail oder SMS. Terminserien werden über die gleichen Kanäle bestätigt.<br><br>
                        </p>
                    <div>
                        <div class="termin-zusammen" v-on-scroll.repeat>
                            <img src="../assets/icon_liege.png" alt=""/>
                            <img src="../assets/icon_patient_a.png" alt=""/>
                            <img src="../assets/icon_therapeut_a.png" alt=""/>
                            <img src="../assets/icon-zeit.png" alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    data() {return{
        tab: ''
    }},
}
</script>

<style lang="scss" scoped>
@use '../styles/mixins' as *;

.termin-zusammen {
	position: relative;
	& > img {
		opacity: 0;
		transition: opacity 0.2s ease-in-out;
		@include nth-of-type-in(2, 4) {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
        }
        @include gestaffelte-verzoegerung(1, 4, 0.8s);
	}
	&.visible {
		& > img {
			opacity: 1;
			transition-delay: 0 !important;
		}
	}
}
</style>